import React from 'react';
/**
 * @returns {JSX.Element} BinIcon svg
 */
export default function BinIcon() {
  return (
    <svg
      viewBox='0 0 28 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 25H7C5.89543 25 5 24.1046 5 23V5H23V23C23 24.1046 22.1046 25 21 25Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 19V11'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17 19V11'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 5H27'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 1H11C9.89543 1 9 1.89543 9 3V5H19V3C19 1.89543 18.1046 1 17 1Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
