import React from 'react';

/**
 * @returns {JSX.Element} PaymentRocket svg
 */
export default function PaymentRocket() {
  return (
    <svg
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5003 0.999878L20.3089 1.24356C18.5901 1.43457 16.9875 2.20479 15.7647 3.42753L5.80594 13.3849L10.1115 17.6919L20.0723 7.73456C21.2957 6.5122 22.0661 4.90966 22.2566 3.19096L22.5003 0.999878Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.80632 13.3849L2.12827 12.1584C1.87783 12.0752 1.6884 11.8682 1.62776 11.6114C1.56712 11.3546 1.64394 11.0847 1.83074 10.8984L2.06633 10.6628C3.69807 9.03098 6.11187 8.46103 8.30134 9.19058L9.57576 9.61498L5.80632 13.3849Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.80632 13.3849L2.12827 12.1584C1.87783 12.0752 1.6884 11.8682 1.62776 11.6114C1.56712 11.3546 1.64394 11.0847 1.83074 10.8984L2.06633 10.6628C3.69807 9.03098 6.11187 8.46103 8.30134 9.19058L9.57576 9.61498L5.80632 13.3849Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1111 17.6919L11.3378 21.3695C11.421 21.6199 11.628 21.8093 11.8849 21.8699C12.1417 21.9305 12.4116 21.8537 12.598 21.6669L12.8346 21.4314C14.4663 19.7997 15.0359 17.3862 14.306 15.1973L13.8836 13.9281L10.1111 17.6919Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1111 17.6919L11.3378 21.3695C11.421 21.6199 11.628 21.8093 11.8849 21.8699C12.1417 21.9305 12.4116 21.8537 12.598 21.6669L12.8346 21.4314C14.4663 19.7997 15.0359 17.3862 14.306 15.1973L13.8836 13.9281L10.1111 17.6919Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.55121 18.2312C7.552 18.8373 7.31116 19.4186 6.88201 19.8466C5.99043 20.7381 1.5 22.0001 1.5 22.0001C1.5 22.0001 2.76223 17.5073 3.65382 16.6189C4.04548 16.2263 4.56678 15.9899 5.12017 15.9539'
        stroke='#08081B'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
