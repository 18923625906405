import React from 'react';

/**
 * @returns {JSX.Element} InfoIcon svg
 */
export default function InfoIcon() {
  return (
    <svg
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 37.1673C29.2048 37.1673 36.6667 29.7054 36.6667 20.5007C36.6667 11.2959 29.2048 3.83398 20 3.83398C10.7953 3.83398 3.33337 11.2959 3.33337 20.5007C3.33337 29.7054 10.7953 37.1673 20 37.1673Z'
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 27.1667V20.5'
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 13.832H20.0167'
        strokeWidth='2.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
