import React from 'react';

/**
 * @returns {JSX.Element} HomeIcon svg
 */
export default function HomeIcon() {
  return (
    <svg
      viewBox='0 0 37 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d={
          'M35.75 34.7445C35.7475 35.5717 35.0776 36.2416 34.2504'
        + ' 36.2441H23.75V28.375C23.75 20.5 13.25 20.5 13.25 28.375V36.2441H2.7496C1.92244'
        + ' 36.2416 1.25253 35.5717 1.25 34.7445V15.5441C1.27654 15.0758 1.48378 14.6361'
        + ' 1.82807 14.3175L18.5 1.74414L35.1719 14.3175C35.5162 14.6361 35.7235 15.0758'
        + ' 35.75 15.5441V34.7445Z'
        }
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
